import {graphql} from 'gatsby';
import {navigateTo} from 'gatsby-link';
import * as React from 'react';
import {ChangeEvent, FormEvent} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Button, FormGroup, Input, Label} from 'reactstrap';
import withI18next from '../../../plugins/gatsby-plugin-i18next/withI18next';
import MainLayout from '../../layouts/main';
import constants from '../../utils/constants';

const styles = require('./contact.module.scss');

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

interface Props extends WithTranslation {
  data: {
    site: {
      siteMetadata: {
        title: string,
      },
    },
  };
}

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class Page extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  public render() {
    const {data, t} = this.props;

    return (
      /*
       * Notice that this form makes use of honeypot and netlify.
       */
      <MainLayout currentPage={constants.PAGE_CONTACT_US}>
        <h1>{t(constants.PAGE_CONTACT_US + '-title')}</h1>
        <p>{t(constants.PAGE_CONTACT_US + '-desc', {title: data.site.siteMetadata.title})}</p>
        <form
          className={styles.form}
          data-netlify-recaptcha="true"
          data-netlify="true"
          method="POST"
          name="feedback"
          action="/thankYou"
          onSubmit={this.handleSubmit}
        >
          <FormGroup>
            <Label>{t(constants.PAGE_CONTACT_US + '-email')}</Label>
            <Input type="email" name="email" required={true} onChange={this.handleChange}/>
          </FormGroup>
          <FormGroup>
            <Label>{t(constants.PAGE_CONTACT_US + '-feedback')}</Label>
            <Input type="textarea" name="message" required={true} onChange={this.handleChange}/>
          </FormGroup>
          <FormGroup>
            <ReCAPTCHA
              sitekey={RECAPTCHA_KEY!}
              onChange={this.handleRecaptcha}
            />
          </FormGroup>
          <Button type="submit" color="primary">{t(constants.PAGE_CONTACT_US + '-submit')}</Button>
        </form>
      </MainLayout>
    );
  }

  private handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({[e.target.name]: e.target.value});
  }

  private handleSubmit(e: FormEvent) {
    e.preventDefault();
    const that = this;
    const form = e.target as HTMLFormElement;
    fetch('/contact/', {
      body: encode({
        'form-name': form.getAttribute('name'),
        ...that.state,
      }),
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      method: 'POST',
    })
      .then(() => navigateTo(form.getAttribute('action')!))
      .catch((error) => alert(error));
  }

  private handleRecaptcha(value: any) {
    this.setState({'g-recaptcha-response': value});
  }
}

export default withI18next()(withTranslation()(Page));

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
